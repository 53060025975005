<template lang="pug">
  v-container.d-flex.align-center.justify-center.full-height
    the-loading(v-if="loading")

    v-alert(
      v-else
      :type="success ? 'success' : 'error'"
      outlined
    )
      p(v-if="success") Электронная почта успешно подтверждена.
      p(v-else) Не удалось подтвердить электронную почту.
      v-btn(
        color="primary"
        outlined
        to="/"
      ) Перейти на главную

</template>

<script>
import API from '@/services/check-enrollments';
import TheLoading from '@/components/TheLoading';

export default {
  name: 'ConfirmEmail',
  components: { TheLoading },
  data: () => ({
    loading: true,
    success: false,
  }),
  created() {
    this.confirmEmail();
  },
  methods: {
    confirmEmail() {
      const url = new URL(window.location.href);
      const token = url.searchParams.get('token');
      const region = url.searchParams.get('region');

      const request = region === 'yanao' ? API.confirmEmailYanao : API.confirmEmail;

      this.$rHandler(
        request(token),
        () => { this.success = true; },
      ).finally(() => { this.loading = false; });
    },
  },
};
</script>
